<template>
  <div class="container-fluid mt-3">
    <div class="d-flex flex-wrap mb-3 mb-sm-0 align-items-center justify-content-between">
      <Breadcrumb current_page="جميع الأخبار"/>
      <el-button class="ms-2 mb-3" type="success" @click="$router.push('/add-breaking-news')">
        إضافة خبر عاجل جديد
      </el-button>
    </div>
    <el-card>
      <el-table id="top-table" :data="news" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">

        <el-table-column prop="id" class-name="px-3" label="الترتيب" width="80"/>
        <el-table-column prop="content" class-name="px-3" label="العنوان" width="500"/>
        <el-table-column prop="time_to_live" class-name="px-3" label="مدة الخبر" width="150"/>
        <el-table-column label="الأدوات" width="120" class-name="control-btns">
          <template #default="scope">

            <el-tooltip class="item" effect="dark" content="تعديل الخبر" placement="top">
              <el-button @click="editNews(scope.row.id)" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
              </el-button>
            </el-tooltip>

<!--            <el-popconfirm-->
<!--                hide-icon-->
<!--                confirmButtonType="danger"-->
<!--                cancelButtonType="default"-->
<!--                confirmButtonText="نعم"-->
<!--                cancelButtonText="لا"-->
<!--                iconColor="red"-->
<!--                @confirm="confirmDeactivate(scope.row.id)"-->
<!--                title="هل أنت متأكد أنك تريد تعطيل هذا الخبر؟">-->
<!--              <template #reference>-->
<!--                <el-button title="تعطيل الخبر" class="py-1 px-2 btn btn-outline-warning">-->
<!--                  <i class="fas fa-minus-circle"/>-->
<!--                </el-button>-->
<!--              </template>-->
<!--            </el-popconfirm>-->

            <el-popconfirm
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="confirmDelete(scope.row.id)"
                title="هل أنت متأكد أنك تريد حذف هذا الخبر؟">
              <template #reference>
                <el-button title="حذف الخبر" class="py-1 px-2 btn btn-outline-danger">
                  <i class="far fa-trash-alt"/>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>


      <pagination v-if="news.length" v-model="page" :records="total" :per-page="per_page" :options="paginationOptions" @paginate="fetchAllNews"/>

    </el-card>


  </div>
</template>

<script>
import {deleteBreakingNews, getBreakingNews} from "@/services/breaking_news";
import Breadcrumb from "@/components/Breadcrumb";
import Loader from "@/components/Loader";
import {useToast} from "vue-toastification";

export default {
  name      : "breaking-news",
  components: {
    Loader,
    Breadcrumb
  },
  data()
  {
    return {
      toast: useToast(),
      loading          : true,
      news             : [],
      page             : 1,
      total            : null,
      per_page         : 25,
      paginationOptions: {
        chunk: 5,
        theme: "bootstrap4",
      }
    }
  },
  mounted()
  {
    this.fetchAllNews()
  },
  methods: {
    fetchAllNews()
    {
      this.loading = true
      getBreakingNews(this.page)
          .then(res => {
            this.loading  = false
            this.total    = res.data.total
            this.per_page = res.data.per_page
            this.news     = res.data.data
          })
    },
    editNews(id){
      console.log(id)
      this.$router.push(`/breaking-news/${id}`)
    },
    confirmDeactivate(id){

    },
    confirmDelete(id){
      deleteBreakingNews(id)
        .then(()=> {
          let newsToDelete = this.news.findIndex(n => n.id === id)
          this.news.splice(newsToDelete,1);
          this.toast.success("تم الحذف بنجاح", {
            position: "top-center",
          });
        })
          .catch(()=>{
            this.toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى", {
              position: "top-center",
            });
          })
    }
  }
}
</script>

<style lang="scss">

.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}

.custom-width {
  min-width: 350px;
}

</style>
