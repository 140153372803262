import request from "@/utils/request";

export function getBreakingNews(page = 1)
{
  return request(
    {
      url   : `breaking-news?page=${page}`,
      method: "get"
    }
  )
}


export function getSingleBreakingNews(news_id){
  return request(
    {
      url: `breaking-news/${news_id}`,
      method: "get"
    }
  )
}

export function disableBreakingNews(id) {
  return request(
    {
      url: "disableBreakingnews",
      method: "post",
      data: {
        id
      }
    }
  )
}

export function createBreakingNews(data) {
  return request(
    {
      url: "breaking-news",
      method: "post",
      data
    }
  )
}

export function updateBreakingNews(id,data){
  return request(
    {
      url: `breaking-news/${id}`,
      method: "put",
      data
    }
  )
}

export function deleteBreakingNews(news_id) {
  return request(
    {
      url: `breaking-news/${news_id}`,
      method: "delete"
    }
  )
}
